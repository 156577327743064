const theme = {
  space: [0, 4, 8, 28, 35, 47, 75, 256, 512],
  fonts: {
    body: '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Fjalla One", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontSizes: [12, 14, 16, 19, 24, 28, 42, 64, 96],
  fontWeights: {
    link: 300,
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.58,
    heading: 1.38,
  },
  colors: {
    text: "#2f2c3d", //Primary Purple
    accent: "#ffa618", //Orange
    // primary: "#268bd5", //Primary Blue
    primary: "#1c73b2", //Primary Blue
    secondary: "#8d73fe", // Definitely Not Purple
    muted: "#f8f6ff", // Lavender Gray
  },
  layout: {
    container: {
      position: "relative",
      maxWidth: 1280,
      padding: "0 20px",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      scrollBehavior: "smooth",
    },
    section: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    h1: {
      color: "text",
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h2: {
      color: "text",
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h3: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
  },
  /**
   * Custom Component Styles
   */
  links: {
    nav: {
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: "link",
      fontSize: 3,
      textDecoration: "none",
    },
    button: {
      color: "#fff",
    },
  },
  buttons: {
    primary: {
      transition: "0.2s ease-in-out",
      cursor: "pointer",
      padding: "14px 40px",
      fontSize: "16px !important",
      borderRadius: 100,
      color: "#fff",
      bg: "primary",
      "&:hover": {
        bg: "primary",
        filter: "darken(10%)",
        color: "#fff !important",
      },
      "&:active": {
        bg: "secondary",
        color: "#fff !important",
      },
      "&:focus": {
        bg: "secondary",
        color: "#fff !important",
      },
    },
    ghost: {
      transition: "0.2s ease-in-out",
      cursor: "pointer",
      fontSize: "16px !important",
      borderRadius: 100,
      color: "primary",
      fontWeight: "heading",
      textDecoration: "none",
      bg: "transparent",
      "&:hover": {
        color: "secondary",
      },
      "&:active": {
        color: "secondary",
      },
      "&:focus": {
        color: "secondary",
      },
    },
    link: {
      transition: "0.2s ease-in-out",
      cursor: "pointer",
      padding: "14px 40px",
      fontSize: "16px !important",
      fontWeight: "bold",
      borderRadius: 100,
      color: "#fff",
      bg: "primary",
      "&:hover": {
        bg: "secondary",
        color: "#fff !important",
      },
      "&:active": {
        bg: "secondary",
        color: "#fff !important",
      },
      "&:focus": {
        bg: "secondary",
        color: "#fff !important",
      },
    },
  },
  cards: {
    primary: {
      backgroundColor: "#fff",
      padding: 3,
      borderRadius: 20,
      boxShadow: "0 19px 44px 0 rgba(117, 68, 178, 0.18)",
    },
    image: {
      backgroundColor: "#fff",
      borderRadius: 20,
      boxShadow: "0 19px 44px 0 rgba(117, 68, 178, 0.18)",
      "& > div": {
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 3,
        paddingTop: 1,
      },
    },
  },
  text: {
    h1: {
      color: "text",
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h2: {
      color: "text",
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h3: {
      color: "text",
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [2, 5],
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    paragraph: {
      maxWidth: 900,
      color: "rgb(0 0 0 / 60%)",
      margin: "1em auto",
      lineHeight: 1.78,
    },
    paragraphCentered: {
      maxWidth: 900,
      color: "rgb(0 0 0 / 60%)",
      margin: "1em auto",
      textAlign: "center",
      fontSize: 3,
    },
  },
};

export default theme;
